export default function useFormValidation ($v, submitted) {
  const validateState = (name) => {
    const { $dirty, $error } = $v.value[name]

    if (submitted.value) {
      return $dirty ? !$error : null
    }

    return null
  }

  return {
    validateState
  }
}
