/*
 * Capitalize text
 * Install:
 * import { capitalize } from './core/helpers/textUtils'
 *
 * Usage:
 * capitalize(STRING)
 */

export const capitalize = (string) => {
  if (!string) return ''

  const value = string.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}


/*
 Truncate text
 Install:
 import { truncate } from './core/helpers/textUtils'
 Usage:
 truncate(STRING)
 */

export const truncate = (text = '', length = 20, clamp = '...') => {
  if (text === null || text === undefined) return ''
  if (text.length <= length) return text

  let tcText = text.slice(0, length - clamp.length)
  let last = tcText.length - 1

  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1

  // Fix for case when text dont have any `space`
  last = last || length - clamp.length

  tcText = tcText.slice(0, last)

  return tcText + clamp
}
